.header-bar {
  top:0px;
  position:absolute;
  height: 72px;
  display: flex;
  justify-content: initial;
  background-color: #ffffff;
  width: 100%;
  z-index: 10;
  transition: transform 0.2s;
  transform: translateY(0px);
  position:fixed;
  @media screen and (min-width: $md) {
    position:absolute;
    background-color: transparent;
    height: $desktop_header_height;
    align-items: center;
    padding: 0px 100px;
  }
  .header-item{
    &.header-back{
      display: block;
      transition: 0.3s;
    }
    &.header-burger{
      padding-top:10px;
      padding-left:5px;
      transition: 0.3s;
      @media screen and (min-width : ($md+1)) {
        display: none;
      }
    }
    &.header-menu{
      @media screen and (max-width : $md ) {
        display: none;
      }
      ul{
        margin: 0px 0px 0px 0px;
        list-style: none;
        padding: 0px;
        li{
          display: inline-block;
          padding: 0px 40px 0px 0px;
          font-weight: normal;
          font-size: 18px;
          a{
            color: #000033;
            text-decoration: none;
            position: relative;
            &:after{
              content: "";
              display: inline-block;
              position: absolute;
              width: 0;
              left: 50%;
              bottom: -10px;
              height: 3px;
              background-color: #FF9966;
              transform: translateX(-50%);
              opacity: 0;
              transition: all .2s ease;
            }
            &:hover{
              color: #000000;
              &:after{
                width: calc( 100% + 10px );
                opacity: 1;
              }
            }
          }
        }
      }
    }
    &.header-logo{
      padding-top:18px;
      position: absolute;
      left: 50%;
      transition: height 0.3s;

      @media screen and (min-width : ($md+1) ) {
        padding-top:0px;
      }
      .logo{
        height: 36px;
        position: relative;
        left: -50%;
        transition: height 0.3s;
        @media screen and (min-width : ($md+1) ) {
          height: 60px;
        }
        svg {
          height: 100%;
          width: auto;
          #Trace_2{
            fill: #000033;
          }
        }
      }
    }
    &.header-button{
      display: none;
      margin-left: auto;
      padding:15px 20px;
      @media screen and (min-width : ($md+1) ) {
        /*display: block;*/
      }
    }
  }
}

.page-content {
  padding-top: 72px;
  @media screen and (min-width: $md) {
    padding-top: $desktop_header_height;
  }
}
  body .stick{
    .header-bar{
      /*position: fixed;
      top: -72px;
      transform: translateY(72px);
      background-color: #000033;
      justify-content: center;*/
      @media screen and (min-width: $md) {
        position: fixed;
        top: -72px;
        transform: translateY(72px);

        height: 100px;
        background-color: #ffffff;
        padding: 0px 38px;
      }
      .header-button{
        //display: block;
        @media screen and (min-width: $md) {
          display: block;
            padding: 0px;
        }
      }
      .header-burger {
        .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after{
          //background-color: #ffffff;
        }
      }
      .header-logo {
        //position: initial;
        @media screen and (min-width: $md) {
          left: calc( 50% - 60px );
          position: absolute;
        }
        .logo {
          //position: initial;
          @media screen and (min-width: $md) {
            position: initial;
            height: 53px;
          }
          svg {
            #Trace_2 {
            //  fill: #ffffff;
              @media screen and (min-width: $md) {
                fill: #000000;
              }
            }
          }
        }
      }
    }
  }


.hamburger{
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity,filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  .hamburger-box{
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
      .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after{
        position: absolute;
        width: 22px;
        height: 3px;
        transition-timing-function: ease;
        transition-duration: .30s;
        transition-property: transform, top, left;
        background-color: #000000;
    }
    .hamburger-inner{
      top: 50%;
      display: block;
      margin-top: -2px;
      &:after{
        display: block;
        content: "";
        bottom: -7px;
      }
      &:before{
        display: block;
        content: "";
        top: -7px;
      }
    }

  }
}
body.menu-is-open{
  .hamburger .hamburger-box{
    .hamburger-inner{
      //background-color: rgba(0,0,0,0) ;
      left: -100%;
      &:after{
        left:100%;
        top: 0px;
        transform: rotate(-45deg);
      }
      &:before{
        left: 100%;
        top: 0px;
        transform: rotate(45deg);
      }
    }
  }

}
/*
root: {
  //  flexGrow: 1,
  position: "relative",
  overflow: "hidden",
  height: "72px"
},
logo: {
  textAlign: 'center',
  display: 'inline-block'
},
circle:{
  position: 'absolute',
  top: '0px',
  right: '0px',
  zIndex: 0,
  height: '25vh'
},
barContainer:{
  zIndex: 1,
  position: 'relative',
  height: '100%'
},
menuButton:{
  marginLeft:'20px'
}*/

.is-creating-flipbook {
  .page-content{
    padding-top: 100px;
  }
  .header-bar{
    position: absolute;
    @media screen and (max-width: $md+1) {
      height: 100px;

    }
    .header-item {

      &.header-logo {

        @media screen and (max-width: $md+1) {
          padding-top: 10px;
          background-color: #ffffff;
          width: 100%;
          left: 0px;
          height: 100px;
        }
        .logo {
          height: 60px;
          @media screen and (max-width: $md+1) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            min-height: 73px;
            height: 73px;
          }
        }

      }
    }
  }
}