.load-page{
  height: 100%;

  transition: 0.5s;
  /*padding-top: 20px;*/

  .CircularProgressbar {
    width: 97px;
    .CircularProgressbar-trail{
      stroke: none;
    }
    .CircularProgressbar-path{
      stroke: #FF9966;
      stroke-width: 3px;
    }
  }
  .progress-info{
    text-align: center;
    .circular-progress{
      display: inline-block;
      margin-top: 10px;
    }
    .progress-message{
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
      position: relative;
      margin: 16px;
      .text-highlight{
        color: #FF6767;
        &.success{
          color: #76CBC2;
        }
      }
    }
  }
  .player-video{
    min-height: 261px;
    min-width: 375px;
    max-height: 80vh;
    max-width: 50vw;
    position: relative;
    display: block;
    margin: 0px auto 40px auto;
    height: 51vw;
    width: 100vw;
    video{
      object-fit: cover;
      width: auto;
      /* object-fit: fill; */
      position: absolute;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

  }
}

.react-card-flip{
  position: absolute;
  bottom: 0px;
  @media screen and (min-width: $md) {
    left: 0px;
    width: 100%;
    height: 350px;
    position: absolute;
    bottom: 0px;
  }
  .flip-card{
    position: relative;
    overflow: hidden;
    height: 100%;
    .box{
      padding: 85px 35px 0px 35px;
      margin: 27px 0px 32px 0px;
      position: relative;
      z-index: 1;
      text-align: center;
      height: 100%;
      .avatar{
        overflow: hidden;
        border-radius: 40px;
        width: auto;
        display: inline-block;
      }
      .content{
        color: #ffffff;
        .message{
          font-size: 20px;
          font-style: italic;
          .emoji{
            font-style: normal;
          }
        }
        .age{
          font-size: 14px;
        }
      }
    }
    .card-background{
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 0;
      right: 0px;
      height: 100%;
      img{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100%;
        min-height: 100%;
      }
    }
    &.flip-card-back{
      .card-background{
        svg{
          transform: scaleX(-1);
        }
      }
    }
  }
}

.is-creating-flipbook{
  .page-load .first_row{
      padding-top: calc( 50vh - 360px );
      display: block;
      min-height: calc(700px - #{$mobile_header_height});
      @media screen and (min-width: $md) {
        padding-top: 30px;
        min-height: calc(700px - #{$desktop_header_height});
      }
    }
}

