@mixin brand-faces() {

  @each $face in $brand-faces {
    $face-name: quote( #{nth($face, 1)} );
    $font-weights: nth($face, 2);
    @each $weight in $font-weights {
      @font-face {
        font-family: $face-name;
        src: url( nth($weight, 2) + '.eot?76871668' );
        src: url( nth($weight, 2) + '.eot?76871668#iefix') format('embedded-opentype'),
        url( nth($weight, 2) + '.woff?76871668') format('woff'),
        url( nth($weight, 2) + '.ttf?76871668') format('truetype'),
        url( nth($weight, 2) + '.svg?76871668#rosche') format('svg');
        font-weight: nth($weight, 1);
        font-style: normal;
      }
    }
    .font-#{nth($face, 1)} {
      font-family: $face-name;
    }
  }

}

@font-face {
  font-family: 'Roboto Black';
  src: local('Roboto-Black'), url(../fonts/Roboto-Black.woff) format('woff');
}

/** LATO **/
@font-face {
  font-family: 'Lato';
  src: url(../fonts/Lato/Lato-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(../fonts/Lato/Lato-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url(../fonts/Lato/Lato-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(../fonts/Lato/Lato-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

/** Aller **/

@font-face {
  font-family: 'Aller Trial';
  src: url(../fonts/Aller/Aller_Trial_Bd.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Aller Trial';
  src: url(../fonts/Aller/Aller_Trial_BdIt.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Aller Trial';
  src: url(../fonts/Aller/Aller_Trial_Rg.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aller Trial';
  src: url(../fonts/Aller/Aller_Trial_It.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

$sm : 600px;
$md : 960px;
$lg : 1280px;
$xl : 1920px;

$desktop_header_height : 148px;
$mobile_header_height : 72px;

#root{
  /* find a better way*/
  color: #000033;
  font-family: 'Lato';
  background-color: #F2F2F1 !important;
  h1, h2, h3{
    font-family: 'Aller Trial';
  }
}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.BoxIcon{
  height:100px;
}


.box-container{
  &:focus{
    outline: none;
  }
  .box{
    &:focus{
      outline: none;
    }
  }
}

.player-video{
  width: 100%;
  box-shadow: 0px 0px 19px 2px rgba(0,0,0,.1);
  background-color: #FFFFFF;
  overflow: hidden;
  position: relative;
  left: -1px;
  video, img{
    position: relative;
    width: calc( 100% + 1px );
    display: block;
    left: 1px
  }
}


.primary-container{
  position: relative;
  z-index: 1;
  overflow: hidden;
  .secondary-container{
    position: relative;
    padding: 0px 20px;
    z-index: 1;
    h2 {
      font-size: 61px;
      color: #000033;
      text-align: center;
      line-height: 61px;
    }
  }
  .background-container{
    position: absolute;
    z-index: 0;
    left: 0px;
    height: 100%;
    top: 0px;
  }
}



ol.bullet-number{
  list-style: none;
  counter-reset: inst;
  margin-top: 30px;
  padding-left: 32px;
  li{
    /*margin: 11px 0;*/
    padding-top: 1em;
    display: block;
    position: relative;
    counter-increment: inst;
    :before{
      content: counter(inst);
      color: #ffffff;
      font-size: 11px;
      font-weight: bold;
      position: absolute;
      --size: 22px;
      left: calc(-1 * var(--size) - 10px);
      line-height: var(--size);
      width: var(--size);
      height: var(--size);
      top: 12px;
      background: #FF9966;
      border-radius: 50%;
      text-align: center;
    }
  }
}


button,.button{
  z-index: 9;
  padding:14px 64px;
  display: block;
  font-size:20px;
  font-weight:bold;
  border-radius: 45px;
  background-color: #FF6767;
  box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.17);
  color:#ffffff;
  font-size: 20px;
  text-align: center;
  border: solid 3px #FF6767;
  text-decoration: none;
  display: block;
  width: auto;
  transition: 0.5s;
  max-width: 336px;
  position: relative;
  margin: auto;
  @media screen and (min-width: $md) {
    width: auto;
  }
  &.small-button{
    font-size: 18px;
    padding:10px 35px;
  }
  &:hover{
    background-color: #FFFFFF;
    color: #FF6767;
    cursor: pointer;
  }
  &.white-btn{
    background-color: #FFFFFF;
    color: #000033;
    border-color: rgba(0,0,51,0.2);
    border-width: 1px;
    cursor: pointer;
    &:hover{
      background-color: #FF6767;
      color: #FFFFFF;
      border-color: #FF6767;
      cursor: pointer;
    }
  }
  &.btn-sticky{
    position: fixed;
    bottom: 30px;
    margin: auto;
    left: 18px;
    right: 18px;
    text-align: center;
    width: auto;
    @media screen and (min-width: $md) {
      left: calc(50% + 245px);
      right: inherit;
    }
    @media screen and (min-width: $lg) {
      left: calc(50% + 400px);
      right: inherit;
    }
  }
}

.highlight-link-container{
  text-align: center;
  margin-top: 27px;
  margin-bottom: 27px;
  a{
    text-decoration: none;
    font-size: 20px;
    color: #FF9966;
    font-weight: bold;
    text-align: center;
    svg{
      position: relative;
      top: 1px;
      left: 22px;
      fill: #FF9966;
    }
  }
}



/** custom slick slider **/
.custom-slider{
  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    @media screen and (min-width: $md) {
      display: block;
    }
  }

  .custom-slick-arrow{
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.07);
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    border-radius: 15px;
    display: block;
    position: absolute;
    top: calc( 50% - 15px );
    z-index: 1;
    right: inherit;
    left: 0px;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
      opacity: 0.8;
    }
    &.custom-slick-next{
      right: 0px;
      left: inherit;
      transform-origin: center;
      transform: rotate(180deg);
    }
    span{
      width: 40%;
      height: 4px;
      left: 30%;
      display: block;
      position: absolute;
      border-radius: 2px;
      background-color: black;
      transform-origin: 0;
      transform: rotate(45deg);
      top: calc( 50% - 3px );
      & + span{
        top: calc( 50% - 1px );
        transform: rotate(-45deg);
      }
    }

  }

}


.accordion-button{
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 5px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: opacity,filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  .accordion-button-box{
    position: relative;
    display: inline-block;
    width: 13px;
    height: 13px;
    .accordion-button-inner, .accordion-button-inner:before, .accordion-button-inner:after{
      position: absolute;
      width: 13px;
      height: 3px;
      transition-timing-function: ease;
      transition-duration: 0.3s;
      transition-property: transform;
      background-color: #000000;
      transform-origin: center;
    }
    .accordion-button-inner{
      height: 0px;
      top: 50%;
      display: block;
      margin-top: 0px;
      transform: rotate( 45deg );
      &:after{
        display: block;
        content: "";
        bottom: -1px;
        transform: rotate( 45deg );
      }
      &:before{
        display: block;
        content: "";
        top: -2px;
        transform: rotate( 135deg );
      }
    }

  }
}

.accordion{
  border-bottom: 1px solid #000033;
  .accordion-item{
    width: 100%;
    position: relative;
    border-top: 1px solid #000033;
    padding: 25px 25px 25px 5px;
   /* min-height: 132px;*/
    .accordion-button{
      position: absolute;
      right: 10px;
      top: 25px;
    }
    .accordion-item-label{
      font-size: 18px;
      font-weight: bold;
      color: #FF6767;
    }
    .accordion-item-content{
      font-size: 16px;
      width: 100%;
      padding: 20px 0px 0px 0px;
    }
    &.is-open{

      .accordion-button-inner{
        transform: rotate( 0deg );
        &:before{
          transform: rotate( 0deg );

        }
        &:after{
          transform: rotate( 0deg ) translateX( 0px ) translateY( 0px ) ;

        }
      }

    }
  }
}


.first-top-bg{
  position: absolute;
  width: 100%;
  bottom: 0px;
  display: block;
  top: 0px;
  height: 100vh;
  overflow: hidden;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  @media screen and (min-width: $md) {
    // top: -$desktop_header_height;
  }
  .bg-01{
    display: none;
    transition: 1s;
    @media screen and (min-width: $md) {
      display: block;
      left: -100%;
      top: 120px;
      width: auto;
      position: absolute;
      background-color: #FFF4F4;
      height: calc( 100vh - #{$desktop_header_height} + 30px );
      .page-home &{
        left: 0px;
      }
    }
  }
  .bg-02{
    position: fixed;
    right: 0px;
    height: 100%;
  }
  .background-left{
    width: 50%;
    height: 100%;
    display: inline-block;
    background-color: #FFF4F4;
  }
  .background-right{
    width: 50%;
    height: 100%;
    display: inline-block;
    background-color: #FFF4F4;
  }
}


.back-button{
  width: 24px;
  height: 24px;
  margin: 24px 0px 0px 14px;
  position: relative;
  display: block;
  .back-button-inner{
    position: relative;
    top: 10px;
    &:before,&:after{
      content: "";
      position: absolute;
      width: 12px;
      height: 3px;
      transition-timing-function: ease;
      transition-duration: .30s;
      transition-property: transform, top, left;
      background-color: #000000;
    }
    &:before{
      transform: rotate( -45deg );
      top:-3px;
    }
    &:after{
      top:3px;
      transform: rotate( 45deg );
    }
  }
}


.page-content{
  padding-bottom: 40px;
  .back-button{
    transition: 0.5s;
    position: fixed;
    z-index: 10;
    left: calc( 15vw - 40px );
    top: calc( 100vh - 115px - 79px );
    border: solid 3px #FF6767;
    height: 79px;
    border-radius: 79px;
    width: 79px;
    .back-button-inner{
      width: 10.5px;
      height: 17px;
      left: calc( 50% - 8px );
      position: absolute;
      top: calc( 50% - 1.5px );
      &:before,&:after{
        background-color: #FF6767;
      }
    }
  }
}

.is-creating-flipbook .page-content{
  .back-button{
    opacity: 0;
  }
}



.avatar{
  overflow: hidden;
  border-radius: 40px;
  height : 79px;
  width: 79px;
  width: auto;
  display: inline-block;
  img{
    height: 100%;
  }
}


.page-action-button{
  text-align: center;
  position: fixed;
  left: 50%;
  transform: translateX( -50% );
  max-width:80%;
  min-width: 336px;
  bottom: 30px;
  @media screen and (min-width: $md) {
    transition: 0.5s;
    //position: fixed;
    left: initial;
    z-index: 10;
    right: calc( 8vw - 40px );
    transform: translateX(-00%);
    top: calc( 100vh - 115px - 50px);
    border: solid 3px #FF6767;
    bottom: inherit;
    max-width: min-content;
    min-width: auto;
  }
}


.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.page-content{
  overflow: hidden;
  display: block;
  position: relative;
  min-height: 100vh;
}





.first-top-bg {
  height: 100%;
  min-height: calc(100vh + #{$mobile_header_height});
  @media screen and (min-width: $md) {
    min-height: calc(100vh + #{$desktop_header_height});
  }
  @media screen and (max-height: 750px) {
    min-height: calc(600px + #{$mobile_header_height});
    @media screen and (min-width: $md) {
      min-height: calc(600px + #{$desktop_header_height});
    }
  }
}

.first_row {
  display: block;
  min-height: calc(600px - #{$mobile_header_height});
  @media screen and (min-width: $md) {
    min-height: calc(100vh - #{$desktop_header_height});
  }
  @media screen and (max-height: 750px) {
    min-height: calc(600px - #{$mobile_header_height});
    @media screen and (min-width: $md) {
      min-height: calc(600px - #{$desktop_header_height});
    }
  }

}



.player-btn{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  svg{
    position: absolute;
    top: calc( 50% - 35px );
    left: calc( 50% - 140px );
    #Groupe_55{
      transition: 0.5s;
      transform: translate(85.529px, 6px);
      transform-origin: 31.9px 31.9px;
      path{
        transition: 0.5s;
      }
    }
    #Groupe_56{
      path{
        transition: 0.5s;
      }
    }
  }
  &:hover{
    #Groupe_55{
      transform: translate(85.529px, 6px) rotate(300deg);
      path{
        fill:#FF6767;
      }
    }
    #Groupe_56{
      path{
        fill:rgb(255, 153, 102);
      }
    }
  }
}




/** hack remove play button **/
video::-webkit-media-controls-panel,
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}